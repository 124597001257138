// Branching logic based on the Authorize Flow Version.
import {PopupWindowParams} from 'types/loginButton';

import {
  AUTHORIZE_POPUP_HEIGHT,
  AUTHORIZE_POPUP_WIDTH,
} from '../../../constants/loginButton';
import {
  OAuthParams,
  OAuthParamsV1,
  ShopActionType,
  LoginButtonVersion as Version,
} from '../../../types';
import {openPopupWindow} from '../../../common/utils';

import {buildPayAuthorizeUrl, getPayAuthDomain} from './authorizeV1';
import {buildCoreAuthorizeUrl, getCoreAuthDomain} from './authorizeV2';

/**
 * Builds the Authorize URL to use for the button or popup. Supports two
 * flow versions:
 * 1. Shop Login M1 - calls Shop Pay directly. Passes in clientId explicitly.
 * 2. Shop Login M2 - proxies auth requests through Core. Core injects the clientId.
 * @param {object} params The root parameter object.
 * @param {string} params.apiKey The app's api key.
 * @param {Version} params.version The implementation version - '1' or '2'.
 * @param {string} params.analyticsTraceId Links analytics events together in one flow.
 * @param {string} params.analyticsContext Defines the context in which this authorize flow is used (i.e.
 * "loginWithShopClassicCustomerAccounts" or "loginWithShopSelfServe") to differentiate events when the same
 * flow is used in different contexts.
 * @param {boolean} params.isFullView Set to true to show the full login form, false to show the login button only.
 * @param {string} params.flow The flow to use for the login.
 * @param {boolean} params.emailVerificationRequired If `true` Pay will only respond with email verified users.
 * Otherwise Pay will respond with all users.
 * @param {boolean} params.signUpEnabled If `false` and Pay does not find a user with the given email address, it will
 * not show the Sign Up flow. Use when doing headless user lookups.
 * @param {OAuthParams} params.oauthParams The OAuth params for OAuth flows (such as code or implicit flow).
 * Note that for the implicit flow, the only required attribute in oauthParams is the clientId.
 * @param {boolean} params.avoidPayAltDomain If `true` the authorize URL will not use the Pay Alt domain.
 * @param {boolean} params.hideCopy If `true`, Pay will not show any copy around the ULC.
 * @param {boolean} params.modalCustomized If `true`, Pay will verify the client is permitted to customize the modal.
 * @param {PopupWindowParams} params.popupWindowParams The params to configure a popup window when redirect_type is pop_up.
 * @param {boolean} params.consentChallenge If `true`, Pay will present the user with the personalization consent
 * challenge, if the feature is on and the user hasn't previously consented.
 * @param {string} params.checkoutVersion Checkout version for analytics.
 * @param {string} params.checkoutToken Checkout token for analytics.
 * @param {string} params.shopId Shop ID
 * @param {boolean} params.requireVerification If `true`, Pay will not render the 1-tap flow cookied users
 * @returns {string} The Authorize URL
 */
export const buildAuthorizeUrl = ({
  version,
  apiKey,
  analyticsTraceId,
  analyticsContext,
  isFullView,
  flow,
  flowVersion,
  emailVerificationRequired,
  signUpEnabled,
  oauthParams,
  avoidPayAltDomain,
  hideCopy,
  modalCustomized,
  popupWindowParams,
  consentChallenge,
  checkoutVersion,
  checkoutToken,
  shopId,
  requireVerification,
}: {
  version: Version;
  apiKey?: string;
  analyticsTraceId?: string;
  analyticsContext?: string;
  isFullView?: boolean;
  flow?: ShopActionType;
  flowVersion?: string;
  emailVerificationRequired?: boolean;
  signUpEnabled?: boolean;
  oauthParams?: OAuthParams | OAuthParamsV1;
  popupWindowParams?: PopupWindowParams;
  avoidPayAltDomain?: boolean;
  hideCopy?: boolean;
  modalCustomized?: boolean;
  consentChallenge?: boolean;
  checkoutVersion?: string;
  checkoutToken?: string;
  shopId?: string;
  requireVerification?: boolean;
}): string => {
  if (version === '1') {
    if (!isOAuthParamsV1(oauthParams)) return '';

    return buildPayAuthorizeUrl({
      popupWindowParams,
      oauthParams,
      analyticsTraceId,
      analyticsContext,
      isFullView,
      flow,
      flowVersion,
      emailVerificationRequired,
      signUpEnabled,
      avoidPayAltDomain,
      hideCopy,
      modalCustomized,
      apiKey,
      consentChallenge,
      checkoutVersion,
      checkoutToken,
      shopId,
      requireVerification,
    });
  }
  return buildCoreAuthorizeUrl({
    analyticsTraceId,
    analyticsContext,
    isFullView,
    flow,
    flowVersion,
    emailVerificationRequired,
    signUpEnabled,
    hideCopy,
    modalCustomized,
    apiKey,
    consentChallenge,
    checkoutVersion,
    checkoutToken,
    shopId,
    requireVerification,
    oauthParams,
  });
};

/**
 * Checks if the given OAuthParams object is a V1 OAuthParams object.
 * @param {OAuthParams | OAuthParamsV1} oauthParams The OAuthParams object to check.
 * @returns {boolean} True if the given object is a V1 OAuthParams object.
 */
function isOAuthParamsV1(
  oauthParams?: OAuthParams | OAuthParamsV1,
): oauthParams is OAuthParamsV1 {
  return Boolean(
    oauthParams !== undefined &&
      'clientId' in oauthParams &&
      oauthParams?.clientId,
  );
}

/**
 * Opens a popup that shows the login form. Supports two flow versions:
 * 1. Shop Login M1 - calls Shop Pay directly. Passes in clientId explicitly.
 * 2. Shop Login M2 - proxies auth requests through Core. Core injects the clientId.
 * @param {object} params The root parameter object.
 * @param {string} params.version The implementation version - '1' or '2'.
 * @param {string} params.clientId The Oauth client id for this client.
 * @param {string} params.analyticsTraceId Links analytics events together in one flow.
 * @param {Function} params.onClose The function to call when the popup is closed.
 * @param {boolean} params.avoidPayAltDomain If true, do not use the alternate domain for the popup.
 * @returns {Window} A pointer to the popup's window object.
 */
export const openAuthorizePopup = ({
  version,
  clientId,
  analyticsTraceId,
  avoidPayAltDomain,
  onClose,
}: {
  version: Version;
  clientId?: string;
  analyticsTraceId?: string;
  avoidPayAltDomain?: boolean;
  onClose?: () => void;
}): Window | null => {
  const url = buildAuthorizeUrl({
    avoidPayAltDomain,
    version,
    analyticsTraceId,
    isFullView: true,
    ...(clientId && {oauthParams: {clientId}}),
  });
  return openPopupWindow({
    url,
    width: AUTHORIZE_POPUP_WIDTH,
    height: AUTHORIZE_POPUP_HEIGHT,
    onClose,
  });
};

/**
 * Returns the root Authorize domain. Supports two flow versions:
 * 1. Shop Login M1 - calls Shop Pay directly. Passes in clientId explicitly.
 * 2. Shop Login M2 - proxies auth requests through Core. Core injects the clientId.
 * @param {string} version The implementation version - '1' or '2'.
 * @returns {string} The root Authorize domain for the given version.
 */
export const getAuthDomain = (version: Version) => {
  return version === '1' ? getPayAuthDomain() : getCoreAuthDomain();
};
