import {PopupWindowParams} from 'types/loginButton';

import {ShopActionType, OAuthParamsV1} from '../../../types';
import {PAY_AUTH_DOMAIN} from '../../../common/utils/urls';
import {I18n} from '../../../common/translator/i18n';
import {booleanQueryParam} from '../../../common/utils/booleanQueryParam';

const AUTH_PATH = '/pay/sdk-authorize';

// Path used in place of sdk-authorize when rendering the Pay alt domain
const SESSION_PATH = '/pay/sdk-session';

/**
 * Build the Authorize URL that calls Pay directly.
 * @param {object} params The root parameter object.
 * @param {OAuthParamsV1} params.oauthParams The OAuth params for OAuth flows (such as code or implicit flow). Note that for the
 * implicit flow, the only required attribute in oauthParams is the clientId.
 * @param {string} params.analyticsTraceId Links analytics events together in one flow.
 * @param {string} params.analyticsContext Defines the context in which this authorize flow is used (i.e.
 * "loginWithShopClassicCustomerAccounts" or "loginWithShopSelfServe") to differentiate events when the same
 * flow is used in different contexts.
 * @param {boolean} params.isFullView Set to true to show the full login form, false to show the login button only.
 * @param {string} params.flow The flow to use for the login.
 * @param {string} params.flowVersion The version of the Sign in with Shop flow (eg. "sign_in" or "sign_up").
 * @param {boolean} params.emailVerificationRequired If `true` Pay will only respond with email verified users.
 * Otherwise Pay will respond with all users.
 * @param {boolean} params.signUpEnabled If `false` and Pay does not find a user with the given email address, it will
 * not show the Sign Up flow. Use when doing headless user lookups.
 * @param {boolean} params.avoidPayAltDomain If true, do not use the alternate domain for the popup.
 * @param {boolean} params.hideCopy If `true`, Pay will not show any copy around the ULC.
 * @param {boolean} params.modalCustomized If `true`, Pay will verify the client is permitted to customize the modal.
 * @param {string} params.apiKey The app's api key.
 * @param {PopupWindowParams} params.popupWindowParams Parameters used to configure the popup when redirect_type is pop_up.
 * @param {boolean} params.consentChallenge If `true`, Pay will present the user with the personalization consent
 * challenge, if the feature is on and the user hasn't previously consented.
 * @param {string} params.checkoutVersion Checkout version for analytics.
 * @param {string} params.checkoutToken Checkout token for analytics.
 * @param {string} params.shopId Shop ID
 * @param {boolean} params.requireVerification If `true`, Pay will not render the 1-tap flow cookied users
 * @returns {string} The Authorize URL in Pay
 */
export const buildPayAuthorizeUrl = ({
  oauthParams,
  analyticsTraceId,
  analyticsContext,
  isFullView,
  flow,
  flowVersion,
  emailVerificationRequired,
  signUpEnabled,
  avoidPayAltDomain,
  hideCopy,
  modalCustomized,
  apiKey,
  popupWindowParams,
  consentChallenge,
  checkoutVersion,
  checkoutToken,
  shopId,
  requireVerification,
}: {
  oauthParams: OAuthParamsV1;
  analyticsTraceId?: string;
  analyticsContext?: string;
  isFullView?: boolean;
  flow?: ShopActionType;
  flowVersion?: string;
  emailVerificationRequired?: boolean;
  signUpEnabled?: boolean;
  avoidPayAltDomain?: boolean;
  hideCopy?: boolean;
  modalCustomized?: boolean;
  apiKey?: string;
  popupWindowParams?: PopupWindowParams;
  consentChallenge?: boolean;
  checkoutVersion?: string;
  checkoutToken?: string;
  shopId?: string;
  requireVerification?: boolean;
}): string => {
  const {clientId, redirectType} = oauthParams;
  const scope = oauthParams.scope || 'openid email';
  const responseType = oauthParams.responseType || 'id_token';
  const responseMode = oauthParams.responseMode || 'web_message';
  const redirect = oauthParams.redirectUri || window.location.origin;
  const {popUpName, popUpFeatures} = popupWindowParams || {};

  /* eslint-disable @typescript-eslint/naming-convention */
  const params = new URLSearchParams({
    target_origin: window.location.origin,
    response_mode: responseMode,
    response_type: responseType,
    scope,
    version: '1',
    client_id: clientId,
    ...(redirectType && {redirect_type: redirectType}),
    redirect_uri: redirect,
    // BUILD_LOCALE is used for generating localized bundles.
    // See ./scripts/i18n-dynamic-import-replacer-rollup.mjs for more info.
    // eslint-disable-next-line no-process-env
    locale: process.env.BUILD_LOCALE || I18n.getDefaultLanguage(),
    ...(analyticsTraceId && {analytics_trace_id: analyticsTraceId}),
    ...(analyticsContext && {analytics_context: analyticsContext}),
    ...(apiKey && {apiKey}),
    ...(flow && {flow}),
    ...(flowVersion && {flow_version: flowVersion}),
    ...(oauthParams.codeChallenge && {
      code_challenge: oauthParams.codeChallenge,
    }),
    ...(oauthParams.codeChallengeMethod && {
      code_challenge_method: oauthParams.codeChallengeMethod,
    }),
    ...(oauthParams.state && {state: oauthParams.state}),
    ...(checkoutVersion && {checkout_version: checkoutVersion}),
    ...(checkoutToken && {checkout_token: checkoutToken}),
    ...(shopId && {shop_id: shopId}),
    ...booleanQueryParam('full_view', isFullView),
    ...booleanQueryParam(
      'email_verification_required',
      emailVerificationRequired,
    ),
    ...booleanQueryParam('sign_up_enabled', signUpEnabled),
    ...booleanQueryParam('hide_copy', hideCopy),
    ...booleanQueryParam('customize-modal', modalCustomized),
    ...booleanQueryParam('consent_challenge', consentChallenge),
    ...booleanQueryParam('require_verification', requireVerification),
    ...(redirectType === 'pop_up'
      ? {
          pop_up_name: popUpName,
          pop_up_features: popUpFeatures,
        }
      : {}),
  });
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * When `avoidPayAltDomain` is falsy, we will route requests through the shop.app/pay/sdk-session endpoint.
   * The session endpoint will allow us to check for the presence of a Pay user session and hoist it to the
   * "alt" pay.shopify.com/pay/sdk-authorize endpoint.
   */
  const path = avoidPayAltDomain ? AUTH_PATH : SESSION_PATH;
  return `${PAY_AUTH_DOMAIN}${path}?${params}`;
};

export const getPayAuthDomain = () => PAY_AUTH_DOMAIN;
