import {PositionBehaviorType} from '../utils';

/**
 * Get keyframes to fade out elements
 * @returns {Array} The keyframes for this animation
 */
export function getFadeOutKeyframes(): Keyframe[] {
  return [{opacity: 1}, {opacity: 0}];
}

/**
 * Get keyframes to fade in elements
 * @returns {Array} The keyframes for this animation
 */
export function getFadeInKeyframes(): Keyframe[] {
  return [{opacity: 0}, {opacity: 1}];
}

/**
 * Get keyframes to close the element
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @returns {Array} The keyframes for this animation
 */
export function getClosingKeyframes(
  behavior: PositionBehaviorType,
): Keyframe[] {
  if (behavior === PositionBehaviorType.Dynamic) {
    return [
      {
        transform: 'scale(1)',
        opacity: 1,
      },
      {
        transform: 'scale(0)',
        opacity: 0,
      },
    ];
  }

  const transform =
    behavior === PositionBehaviorType.Mobile ? 'translate(0, 100%)' : '';

  return [
    {
      transform: 'translate(0, 0)',
      opacity: 1,
    },
    {transform, opacity: 0},
  ];
}

/**
 * Get keyframes to open the element
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @returns {Array} The keyframes for this animation
 */
export function getOpeningKeyframes(
  behavior: PositionBehaviorType,
): Keyframe[] {
  if (behavior === PositionBehaviorType.Dynamic) {
    return [
      {
        transform: 'scale(0)',
        opacity: 0,
      },
      {
        transform: 'scale(1)',
        opacity: 1,
      },
    ];
  }

  const transform =
    behavior === PositionBehaviorType.Mobile ? 'translate(0, 100%)' : undefined;

  return [
    {
      transform,
      opacity: 0,
    },
    {transform: 'none', opacity: 1},
  ];
}

/**
 * Get keyframes default options
 * @returns {object} The keyframes for this animation
 */
export function getAnimationDefaultOptions(): KeyframeAnimationOptions {
  return {
    easing: 'cubic-bezier(0.32,0.72,0,1)',
    duration: window.matchMedia('(prefers-reduced-motion: reduce)').matches
      ? 0
      : 400,
  };
}

/**
 * Get all focusable elements for a given parent element, considering children provided to slot elements
 * @param {HTMLSlotElement} slot The slot element to get focusable elements from
 * @returns {Array} The focusable elements
 */
export function getFocusableElements(slot: HTMLSlotElement): Element[] {
  const selector =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

  return Array.from(slot.assignedElements()).reduce<Element[]>(
    (focusable, parentElement) => {
      const focusableElements = Array.from(
        parentElement.querySelectorAll(selector),
      );
      if (parentElement.matches(selector)) {
        focusableElements.unshift(parentElement);
      }
      return [...focusable, ...focusableElements];
    },
    [],
  );
}
