import {defineCustomElement} from '../../common/init';
import Bugsnag from '../../common/bugsnag';
import {getValidAttribute} from '../../common/utils/attributeValidator';
import {getAnalyticsTraceId, updateAttribute} from '../../common/utils';
import WebComponent from '../../common/WebComponent';
import {
  ShopLoginEventType,
  ShopActionType,
  LoginButtonVersion as Version,
} from '../../types';
import {
  ATTRIBUTE_ACTION,
  ATTRIBUTE_ANALYTICS_CONTEXT,
  ATTRIBUTE_ANALYTICS_TRACE_ID,
  ATTRIBUTE_AUTO_OPEN,
  ATTRIBUTE_CLIENT_ID,
  ATTRIBUTE_DISABLE_SIGN_UP,
  ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED,
  ATTRIBUTE_HIDE_BUTTON,
  ATTRIBUTE_REDIRECT_TYPE,
  ATTRIBUTE_REDIRECT_URI,
  ATTRIBUTE_RESPONSE_TYPE,
  ATTRIBUTE_RESPONSE_MODE,
  ATTRIBUTE_CODE_CHALLENGE,
  ATTRIBUTE_CODE_CHALLENGE_METHOD,
  ATTRIBUTE_STATE,
  ATTRIBUTE_SCOPE,
  ATTRIBUTE_STOREFRONT_ORIGIN,
  ATTRIBUTE_VERSION,
  ATTRIBUTE_AVOID_PAY_ALT_DOMAIN,
  ATTRIBUTE_FLOW,
  ATTRIBUTE_FLOW_VERSION,
  ATTRIBUTE_ANCHOR_SELECTOR,
  ATTRIBUTE_KEEP_MODAL_OPEN,
  ERRORS,
  LOAD_TIMEOUT_MS,
  ATTRIBUTE_EMAIL,
  ATTRIBUTE_DEV_MODE,
  ATTRIBUTE_MODAL_TITLE,
  ATTRIBUTE_MODAL_DESCRIPTION,
  ATTRIBUTE_MODAL_LOGO_SRC,
  ATTRIBUTE_API_KEY,
  ATTRIBUTE_POP_UP_NAME,
  ATTRIBUTE_POP_UP_FEATURES,
  ATTRIBUTE_MODAL_BRAND,
  ATTRIBUTE_CONSENT_CHALLENGE,
  ATTRIBUTE_CHECKOUT_VERSION,
  ATTRIBUTE_SHOP_ID,
  ATTRIBUTE_FIRST_NAME,
  ATTRIBUTE_LAST_NAME,
  ATTRIBUTE_REQUIRE_VERIFICATION,
  ATTRIBUTE_CHECKOUT_TOKEN,
} from '../../constants/loginButton';

import {buildAuthorizeUrl} from './authorize';
import ShopFollowButton from './shop-follow-button';
import ShopLoginDefault from './shop-login-default';

const actionTypeToTagName: {[key in ShopActionType]: string} = {
  [ShopActionType.Follow]: 'shop-follow-button',
  [ShopActionType.Default]: 'shop-login-default',
  // Prequal uses the same component as Default
  [ShopActionType.Prequal]: 'shop-login-default',
  [ShopActionType.PopUp]: 'shop-login-default',
  [ShopActionType.Custom]: 'shop-login-default',
};

export default class ShopLoginButton extends WebComponent {
  #actionType: ShopActionType | undefined;
  #actionButton: ShopFollowButton | ShopLoginDefault | undefined;

  private _iframe!: HTMLIFrameElement;
  private _clientId: string | undefined;
  private _version: Version | undefined;
  private _emailVerificationRequired = false;
  private _analyticsContext = '';
  private _flowVersion: string | undefined = 'unspecified';
  private _hideButton = false;
  private _disableSignUp = false;
  private _autoOpen = false;
  private _popup: Window | undefined;
  private _loadTimeout: ReturnType<typeof setTimeout> | undefined;
  private _analyticsTraceId: string | undefined;
  private _redirectUri: string | null | undefined;
  private _redirectType: string | null | undefined;
  private _responseType: string | null | undefined;
  private _responseMode: string | null | undefined;
  private _codeChallenge: string | null | undefined;
  private _codeChallengeMethod: string | null | undefined;
  private _state: string | null | undefined;
  private _scope: string | null | undefined;
  private _avoidPayAltDomain = false;
  private _email = '';
  private _keepModalOpen = false;
  private _contentTitle: string | null | undefined;
  private _contentDescription: string | null | undefined;
  private _contentLogo: string | null | undefined;
  private _popUpName: string | undefined;
  private _popUpFeatures: string | undefined;
  private _consentChallenge = false;
  private _shopId: string | undefined;
  private _firstName: string | undefined;
  private _lastName: string | undefined;
  private _requireVerification = false;

  static get observedAttributes(): string[] {
    return [
      ATTRIBUTE_CLIENT_ID,
      ATTRIBUTE_VERSION,
      ATTRIBUTE_FLOW_VERSION,
      ATTRIBUTE_STOREFRONT_ORIGIN,
      ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED,
      ATTRIBUTE_HIDE_BUTTON,
      ATTRIBUTE_DISABLE_SIGN_UP,
      ATTRIBUTE_AUTO_OPEN,
      ATTRIBUTE_REDIRECT_TYPE,
      ATTRIBUTE_REDIRECT_URI,
      ATTRIBUTE_ANALYTICS_CONTEXT,
      ATTRIBUTE_REDIRECT_URI,
      ATTRIBUTE_RESPONSE_TYPE,
      ATTRIBUTE_RESPONSE_MODE,
      ATTRIBUTE_CODE_CHALLENGE,
      ATTRIBUTE_CODE_CHALLENGE_METHOD,
      ATTRIBUTE_STATE,
      ATTRIBUTE_SCOPE,
      ATTRIBUTE_EMAIL,
      ATTRIBUTE_ANCHOR_SELECTOR,
      ATTRIBUTE_DEV_MODE,
      ATTRIBUTE_MODAL_TITLE,
      ATTRIBUTE_MODAL_DESCRIPTION,
      ATTRIBUTE_MODAL_LOGO_SRC,
      ATTRIBUTE_API_KEY,
      ATTRIBUTE_POP_UP_NAME,
      ATTRIBUTE_POP_UP_FEATURES,
      ATTRIBUTE_MODAL_BRAND,
      ATTRIBUTE_CONSENT_CHALLENGE,
      ATTRIBUTE_ANALYTICS_TRACE_ID,
      ATTRIBUTE_CHECKOUT_VERSION,
      ATTRIBUTE_CHECKOUT_TOKEN,
      ATTRIBUTE_SHOP_ID,
      ATTRIBUTE_FIRST_NAME,
      ATTRIBUTE_LAST_NAME,
      ATTRIBUTE_REQUIRE_VERIFICATION,
      ATTRIBUTE_AVOID_PAY_ALT_DOMAIN,
    ];
  }

  constructor() {
    super();
  }

  get popup(): Window | undefined {
    return this._popup;
  }

  // Allows getting the clientId as a property externally. Internally, use the private _clientId variable.
  get clientId(): string | undefined {
    return this._clientId;
  }

  // Allows setting the clientId property externally, reflects value in the attribute.
  set clientId(newClientId: string | undefined) {
    this._clientId = newClientId;
    this.updateAttribute(ATTRIBUTE_CLIENT_ID, newClientId);
    this._updateSrc();
  }

  set redirectUri(newRedirectUri: string | undefined) {
    this._redirectUri = newRedirectUri;
    this.updateAttribute(ATTRIBUTE_REDIRECT_URI, newRedirectUri);
    this._updateSrc();
  }

  get version(): Version | undefined {
    return this._version;
  }

  set version(newVersion: Version | undefined) {
    this._version = newVersion;
    this.updateAttribute(ATTRIBUTE_VERSION, newVersion);
    this._updateSrc();
  }

  get email(): string {
    return this._email;
  }

  set email(newEmail: string) {
    this._email = newEmail;
    this.updateAttribute(ATTRIBUTE_EMAIL, newEmail);
  }

  set firstName(newFirstName: string | undefined) {
    this._firstName = newFirstName;
    this.updateAttribute(ATTRIBUTE_FIRST_NAME, newFirstName);
  }

  set lastName(newLastName: string | undefined) {
    this._lastName = newLastName;
    this.updateAttribute(ATTRIBUTE_LAST_NAME, newLastName);
  }

  set popUpName(newPopUpName: string | undefined) {
    this._popUpName = newPopUpName;
    this.updateAttribute(ATTRIBUTE_POP_UP_NAME, newPopUpName);
    this._updateSrc();
  }

  set popUpFeatures(newPopUpFeatures: string | undefined) {
    this._popUpFeatures = newPopUpFeatures;
    this.updateAttribute(ATTRIBUTE_POP_UP_FEATURES, newPopUpFeatures);
    this._updateSrc();
  }

  connectedCallback(): void {
    this.#actionType = getValidAttribute<ShopActionType>(
      this.getAttribute(ATTRIBUTE_ACTION),
      ShopActionType,
      ShopActionType.Default,
    );
    const modalAnchor = this.getAttribute(ATTRIBUTE_ANCHOR_SELECTOR);
    const modalLogo = this.getAttribute(ATTRIBUTE_MODAL_BRAND);
    const checkoutVersion =
      this.getAttribute(ATTRIBUTE_CHECKOUT_VERSION) || undefined;
    const checkoutToken =
      this.getAttribute(ATTRIBUTE_CHECKOUT_TOKEN) || undefined;
    const devMode = this.getBooleanAttribute(ATTRIBUTE_DEV_MODE);
    const apiKey = this.getAttribute(ATTRIBUTE_API_KEY);
    this._emailVerificationRequired = this.getBooleanAttribute(
      ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED,
    );
    this._keepModalOpen = this.getBooleanAttribute(ATTRIBUTE_KEEP_MODAL_OPEN);
    this._hideButton = this.getBooleanAttribute(ATTRIBUTE_HIDE_BUTTON);
    this._disableSignUp = this.getBooleanAttribute(ATTRIBUTE_DISABLE_SIGN_UP);
    this._autoOpen = this.getBooleanAttribute(ATTRIBUTE_AUTO_OPEN);
    this._avoidPayAltDomain = this.getBooleanAttribute(
      ATTRIBUTE_AVOID_PAY_ALT_DOMAIN,
    );
    this._redirectType = this.getAttribute(ATTRIBUTE_REDIRECT_TYPE);
    this._redirectUri = this.getAttribute(ATTRIBUTE_REDIRECT_URI);
    this._responseType = this.getAttribute(ATTRIBUTE_RESPONSE_TYPE);
    this._responseMode = this.getAttribute(ATTRIBUTE_RESPONSE_MODE);
    this._codeChallenge = this.getAttribute(ATTRIBUTE_CODE_CHALLENGE);
    this._codeChallengeMethod = this.getAttribute(
      ATTRIBUTE_CODE_CHALLENGE_METHOD,
    );
    this._state = this.getAttribute(ATTRIBUTE_STATE);
    this._scope = this.getAttribute(ATTRIBUTE_SCOPE);
    this._email = this.getAttribute(ATTRIBUTE_EMAIL) || '';
    this._contentTitle = this.getAttribute(ATTRIBUTE_MODAL_TITLE);
    this._contentDescription = this.getAttribute(ATTRIBUTE_MODAL_DESCRIPTION);
    this._contentLogo = this.getAttribute(ATTRIBUTE_MODAL_LOGO_SRC);
    this._analyticsContext =
      this.getAttribute(ATTRIBUTE_ANALYTICS_CONTEXT) || '';
    this._flowVersion =
      this.getAttribute(ATTRIBUTE_FLOW_VERSION) || 'unspecified';
    this._analyticsTraceId =
      this.getAttribute(ATTRIBUTE_ANALYTICS_TRACE_ID) || getAnalyticsTraceId();
    this._popUpName = this.getAttribute(ATTRIBUTE_POP_UP_NAME) || undefined;
    this._popUpFeatures =
      this.getAttribute(ATTRIBUTE_POP_UP_FEATURES) || undefined;
    this._consentChallenge = this.getBooleanAttribute(
      ATTRIBUTE_CONSENT_CHALLENGE,
    );
    this._shopId = this.getAttribute(ATTRIBUTE_SHOP_ID) || undefined;
    this._firstName = this.getAttribute(ATTRIBUTE_FIRST_NAME) || undefined;
    this._lastName = this.getAttribute(ATTRIBUTE_FIRST_NAME) || undefined;
    this._requireVerification = this.getBooleanAttribute(
      ATTRIBUTE_REQUIRE_VERIFICATION,
    );

    this.#actionButton = this._createActionButton({
      actionType: this.#actionType,
      attributes: {
        [ATTRIBUTE_VERSION]: this._version,
        [ATTRIBUTE_CLIENT_ID]: this._clientId,
        [ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED]:
          this._emailVerificationRequired,
        [ATTRIBUTE_HIDE_BUTTON]: this._hideButton,
        [ATTRIBUTE_DISABLE_SIGN_UP]: this._disableSignUp,
        [ATTRIBUTE_AUTO_OPEN]: this._autoOpen,
        [ATTRIBUTE_REDIRECT_TYPE]: this._redirectType,
        [ATTRIBUTE_REDIRECT_URI]: this._redirectUri,
        [ATTRIBUTE_ANALYTICS_CONTEXT]: this._analyticsContext,
        [ATTRIBUTE_ANALYTICS_TRACE_ID]: this._analyticsTraceId,
        [ATTRIBUTE_RESPONSE_TYPE]: this._responseType,
        [ATTRIBUTE_RESPONSE_MODE]: this._responseMode,
        [ATTRIBUTE_CODE_CHALLENGE]: this._codeChallenge,
        [ATTRIBUTE_CODE_CHALLENGE_METHOD]: this._codeChallengeMethod,
        [ATTRIBUTE_STATE]: this._state,
        [ATTRIBUTE_SCOPE]: this._scope,
        [ATTRIBUTE_AVOID_PAY_ALT_DOMAIN]: this._avoidPayAltDomain,
        [ATTRIBUTE_FLOW]: this.#actionType,
        [ATTRIBUTE_FLOW_VERSION]: this._flowVersion,
        [ATTRIBUTE_EMAIL]: this._email,
        [ATTRIBUTE_ANCHOR_SELECTOR]: modalAnchor,
        [ATTRIBUTE_API_KEY]: apiKey,
        [ATTRIBUTE_DEV_MODE]: devMode,
        [ATTRIBUTE_KEEP_MODAL_OPEN]: this._keepModalOpen,
        [ATTRIBUTE_MODAL_TITLE]: this._contentTitle,
        [ATTRIBUTE_MODAL_DESCRIPTION]: this._contentDescription,
        [ATTRIBUTE_MODAL_LOGO_SRC]: this._contentLogo,
        [ATTRIBUTE_POP_UP_NAME]: this._popUpName,
        [ATTRIBUTE_POP_UP_FEATURES]: this._popUpFeatures,
        [ATTRIBUTE_MODAL_BRAND]: modalLogo,
        [ATTRIBUTE_CONSENT_CHALLENGE]: this._consentChallenge,
        [ATTRIBUTE_CHECKOUT_VERSION]: checkoutVersion,
        [ATTRIBUTE_CHECKOUT_TOKEN]: checkoutToken,
        [ATTRIBUTE_SHOP_ID]: this._shopId,
        [ATTRIBUTE_FIRST_NAME]: this._firstName,
        [ATTRIBUTE_LAST_NAME]: this._lastName,
        [ATTRIBUTE_REQUIRE_VERIFICATION]: this._requireVerification,
      },
    });
    if (!this.shadowRoot) {
      this.attachShadow({mode: 'open'});
    }
    if (this.#actionButton) {
      this.shadowRoot!.innerHTML = '';
      this.shadowRoot?.appendChild(this.#actionButton);
    }
  }

  disconnectedCallback(): void {}

  attributeChangedCallback(
    name: string,
    _oldValue: string,
    newValue: string | null,
  ): void {
    switch (name) {
      case ATTRIBUTE_CLIENT_ID:
        this._initClientId();
        break;
      case ATTRIBUTE_VERSION:
        this._initVersion();
        break;
      case ATTRIBUTE_REDIRECT_TYPE:
        this._initRedirectType();
        break;
      case ATTRIBUTE_REDIRECT_URI:
        this._initRedirectUri();
        break;
      case ATTRIBUTE_RESPONSE_TYPE:
        this._initResponseType();
        break;
      case ATTRIBUTE_RESPONSE_MODE:
        this._initResponseMode();
        break;
      case ATTRIBUTE_CODE_CHALLENGE:
        this._initCodeChallenge();
        break;
      case ATTRIBUTE_CODE_CHALLENGE_METHOD:
        this._initCodeChallengeMethod();
        break;
      case ATTRIBUTE_STATE:
        this._initState();
        break;
      case ATTRIBUTE_SCOPE:
        this._initScope();
        break;
      case ATTRIBUTE_EMAIL:
        this._initEmail();
        break;
      case ATTRIBUTE_POP_UP_NAME:
        this._initPopUpName();
        break;
      case ATTRIBUTE_POP_UP_FEATURES:
        this._initPopUpFeatures();
        break;
    }

    if (newValue === null) {
      this.#actionButton?.removeAttribute(name);
    } else {
      this.#actionButton?.setAttribute(name, newValue);
    }
  }

  requestShow(email: string): void {
    if (this.#actionButton && 'requestShow' in this.#actionButton) {
      this.#actionButton.requestShow(email);
    }
  }

  listenToInput(inputElement: HTMLInputElement): void {
    if (this.#actionButton && 'listenToInput' in this.#actionButton) {
      this.#actionButton.listenToInput(inputElement);
    }
  }

  stopListeningToInput() {
    if (!(this.#actionButton && 'stopListeningToInput' in this.#actionButton))
      return;

    this.#actionButton.stopListeningToInput();
  }

  // Overrides to enforce type safety
  protected dispatchCustomEvent(type: ShopLoginEventType, detail?: any): void {
    super.dispatchCustomEvent(type, detail);
  }

  private _createActionButton({
    actionType,
    attributes,
  }: {
    actionType: ShopActionType;
    attributes: Record<string, any>;
  }): ShopFollowButton | ShopLoginDefault | undefined {
    const tagName = actionTypeToTagName[actionType];
    if (!tagName) return undefined;

    const actionButton = document.createElement(tagName) as
      | ShopFollowButton
      | ShopLoginDefault
      | undefined;
    if (!actionButton) return undefined;

    Object.entries(attributes).forEach(([key, value]) => {
      if (value) {
        actionButton.setAttribute(key, String(value));
      }
    });

    return actionButton;
  }

  private _initClientId(): void {
    const clientId = this.getAttribute(ATTRIBUTE_CLIENT_ID);
    this.clientId = clientId || undefined;
  }

  private _initVersion(): void {
    const version = this.getAttribute(ATTRIBUTE_VERSION) as Version | null;
    if (version) {
      this.version = version;
    }
  }

  private _initEmail(): void {
    const email = this.getAttribute(ATTRIBUTE_EMAIL);
    if (email) {
      this.email = email;
    }
  }

  private _initRedirectType(): void {
    const redirectType = this.getAttribute(ATTRIBUTE_REDIRECT_TYPE);
    this._redirectType = redirectType || undefined;
  }

  private _initRedirectUri(): void {
    const redirectUri = this.getAttribute(ATTRIBUTE_REDIRECT_URI);
    this.redirectUri = redirectUri || undefined;
  }

  private _initResponseType(): void {
    const responseType = this.getAttribute(ATTRIBUTE_RESPONSE_TYPE);
    this._responseType = responseType || undefined;
  }

  private _initResponseMode(): void {
    const responseMode = this.getAttribute(ATTRIBUTE_RESPONSE_MODE);
    this._responseMode = responseMode || undefined;
  }

  private _initCodeChallenge(): void {
    const codeChallenge = this.getAttribute(ATTRIBUTE_CODE_CHALLENGE);
    this._codeChallenge = codeChallenge || undefined;
  }

  private _initCodeChallengeMethod(): void {
    const codeChallengeMethod = this.getAttribute(
      ATTRIBUTE_CODE_CHALLENGE_METHOD,
    );
    this._codeChallengeMethod = codeChallengeMethod || undefined;
  }

  private _initState(): void {
    const state = this.getAttribute(ATTRIBUTE_STATE);
    this._state = state || undefined;
  }

  private _initScope(): void {
    const scope = this.getAttribute(ATTRIBUTE_SCOPE);
    this._scope = scope || undefined;
  }

  private _initPopUpName(): void {
    this.popUpName = this.getAttribute(ATTRIBUTE_POP_UP_NAME) || undefined;
  }

  private _initPopUpFeatures(): void {
    this.popUpFeatures =
      this.getAttribute(ATTRIBUTE_POP_UP_FEATURES) || undefined;
  }

  private _initLoadTimeout(): void {
    this._clearLoadTimeout();
    this._loadTimeout = setTimeout(() => {
      const error = ERRORS.temporarilyUnavailable;
      this.dispatchCustomEvent('error', {
        message: error.message,
        code: error.code,
      });
      // eslint-disable-next-line no-warning-comments
      // TODO: replace this bugsnag notify with a Observe-able event
      // Bugsnag.notify(
      //   new PayTimeoutError(`Pay failed to load within ${LOAD_TIMEOUT_MS}ms.`),
      //   {component: this.#component, src: this.iframe?.getAttribute('src')},
      // );
      this._clearLoadTimeout();
    }, LOAD_TIMEOUT_MS);
  }

  private _clearLoadTimeout() {
    if (!this._loadTimeout) return;
    clearTimeout(this._loadTimeout);
    this._loadTimeout = undefined;
  }

  private _updateSrc(): void {
    const authorizeUrl = buildAuthorizeUrl({
      avoidPayAltDomain: this._avoidPayAltDomain,
      version: this._version!,
      analyticsTraceId: this._analyticsTraceId,
      flowVersion: this._flowVersion,
      ...(this._version === '1' &&
        this._clientId && {
          oauthParams: {
            clientId: this._clientId,
            redirectUri: this._redirectUri || undefined,
          },
        }),
      popupWindowParams: {
        popUpName: this._popUpName || undefined,
        popUpFeatures: this._popUpFeatures || undefined,
      },
    });

    if (!authorizeUrl || !this._iframe) return;

    this._initLoadTimeout();
    updateAttribute(this._iframe, 'src', authorizeUrl);
    Bugsnag.leaveBreadcrumb('Iframe url updated', {authorizeUrl}, 'state');
  }
}

/**
 * Define the shop-login-button custom element.
 */
export function defineElement() {
  defineCustomElement('shop-login-button', ShopLoginButton);
}
